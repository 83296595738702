<template>
    <div class="reset-password-index-page">
        <h1 class="reset-password-index-page__title">{{ $lang.pages.nuevaContrasena.new_password }}</h1>

        <div class="modal-auth-password">
            <picture class="modal-auth-password__image">
                <img :src="$assets.auth.recovery.passwordWay" alt="image" />
            </picture>
            <form autocomplete="off" class="modal-auth-password__form" @submit.prevent>
                <AuthInputPassword
                    v-model.trim="formData.password"
                    name="password"
                    autocomplete="negative"
                    :placeholder="$lang.pages.nuevaContrasena.write_new_password"
                    class="modal-auth-password__input"
                    :invalid="formValidationStatus.password"
                >
                    {{ formValidationStatus.password }}
                </AuthInputPassword>
                <AuthInputPassword
                    v-model.trim="formData.password_confirmation"
                    name="confirm"
                    autocomplete="negative"
                    :placeholder="$lang.pages.nuevaContrasena.repeat_new_password"
                    class="modal-auth-password__input"
                    :invalid="formValidationStatus.password_confirmation"
                >
                    {{ formValidationStatus.password_confirmation }}
                </AuthInputPassword>
            </form>
            <ButtonGeneric
                class="modal-auth-password__submit-button"
                :class="{ ready }"
                :info="{
                    text: $lang.pages.nuevaContrasena.change_password,
                    icon: $assets.white.logout,
                    handler: resetPassword,
                }"
            ></ButtonGeneric>
            <div v-if="formValidationStatus.token" class="modal-auth-password__token-error">
                <img :src="$assets.red.formError" alt="icon" />
                <p>{{ formValidationStatus.token }}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

import type { Api } from '~~/global'

definePageMeta({
    middleware: ['not-auth-required'],
})

const Route = useRoute()
const { $lang } = useNuxtApp()
const RootStore = useRootStore()
const {
    public: { origin },
} = useRuntimeConfig()

const formData = reactive({
    password: '',
    password_confirmation: '',
    token: Route.query.code as string,
})
const formValidationStatus = reactive({
    password: false as string | boolean,
    password_confirmation: false as string | boolean,
    token: false as string | boolean,
})

const title = $lang.pages.nuevaContrasena.title_seo
const description = $lang.pages.nuevaContrasena.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const ready = computed(() => {
    return (
        formData.password === formData.password_confirmation &&
        !!formData.password?.length &&
        !!formData.password_confirmation?.length
    )
})

const Router = useRouter()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const resetPassword = async () => {
    RootStore.setLoadingStatus(true)

    formValidationStatus.password = false

    formValidationStatus.password_confirmation = false

    formValidationStatus.token = false

    try {
        const result = await $fetch<
            Api.Responses.Auth.FailedRecoveryFinish | Api.Responses.Auth.SuccessRecoveryFinish
        >(endpoints.authentication.recoveryFinish.withToken.path, {
            body: formData,
            baseURL,
            headers: buildHeaders(),
            method: 'POST',
        })

        if (result.feedback === 'data_error') {
            formValidationStatus.password = result.data.password?.[0] || false

            formValidationStatus.password_confirmation = result.data.password_confirmation?.[0] || false

            formValidationStatus.token = result.data.token?.[0] || false

            RootStore.setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.pages.nuevaContrasena.close,
                text: $lang.pages.nuevaContrasena.incorrect_fields,
                type: 'error',
            })
        } else if (result.feedback === 'not_found') {
            RootStore.setSiteNotification({
                duration: 5000,
                dismissButtonText: 'Cerrar',
                text: $lang.pages.nuevaContrasena.token_failed,
                type: 'error',
            })
        } else if (result.feedback === 'data_updated_success') {
            Router.push('/' + $lang.routes.successChangedPassword)
        }
    } catch (e) {
        console.log('Error on update password: ', { e })
    }
    RootStore.setLoadingStatus(false)
}
</script>

<style lang="postcss" scoped>
.reset-password-index-page {
    @apply container pb-5 lg:pb-10;

    &__title {
        @apply my-5 text-center text-4xl lg:my-10;
    }
    &__form {
        @apply mx-auto max-w-[800px] overflow-hidden rounded-lg;
    }
    .modal-auth-password {
        @apply relative mx-auto max-w-lg space-y-5 rounded-lg bg-white p-5 lg:my-10;
        &__image {
            @apply block h-32;
            img {
                @apply mx-auto h-full;
            }
        }
        &__form {
            @apply space-y-3;
        }
        &__submit-button {
            @apply w-full border-2 border-site-primary bg-white text-site-primary transition-colors;
            img {
                @apply transition-all;
            }
            @screen lg {
                &:hover {
                    @apply bg-site-primary-hover text-white;
                    img {
                        @apply brightness-0 invert;
                    }
                }
            }
            &.ready {
                @apply bg-site-primary-hover text-white;
                img {
                    @apply brightness-0 invert;
                }
            }
        }
        &__token-error {
            @apply mb-3 flex w-full flex-grow items-center justify-center space-x-1.5 text-sm text-red-500;
            img {
                @apply w-4;
            }
        }
    }
}
</style>
